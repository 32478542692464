<template>
  <v-alert text type="warning" v-if="display">
    <div class="text-h5" v-text="$t(`alert.invoice.${sAlertType}.title`)" />
    <p v-text="$t(`alert.invoice.${sAlertType}.description`)" />

    <v-divider class="my-4 warning" style="opacity: 0.22"></v-divider>

    <v-row align="center" no-gutters>
      <v-col class="shrink">
        <v-btn color="primary" outlined @click="openFirmForm">
          {{ $t("edit.invoice.customer.data") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script lang="ts">
import { EventBus } from "@/services/event-bus";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AlertMaxUi extends Vue {
  @Prop(Boolean) readonly display!: boolean;
  @Prop(String) readonly alertType!: string;

  openFirmForm() {
    EventBus.emit("open.customer.form.dialog", { firmOnly: true });
  }

  get sAlertType() {
    return this.alertType || "maxui";
  }
}
</script>

<style scoped></style>
